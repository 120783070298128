
import { computed, defineComponent } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },
  setup() {
    const store = useStore();
    const user = computed(() => {
      return store.getters.currentUser;
    });
    return {
      user,
    };
  },
});

const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/graphs/gra004.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "sections",
        route: "/sections",
        svgIcon: "media/icons/duotune/abstract/abs027.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "lists",
        route: "/lists",
        svgIcon: "media/icons/duotune/text/txt009.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "questions",
        route: "/questions",
        svgIcon: "media/icons/duotune/general/gen046.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "admins",
        route: "/admins",
        svgIcon: "media/icons/duotune/general/gen049.svg",
        fontIcon: "bi-app-indicator",
        hidden: "d-none",
      },
      {
        heading: "users",
        route: "/users",
        svgIcon: "media/icons/duotune/communication/com014.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "surveys",
        route: "/surveys",
        svgIcon: "media/icons/duotune/graphs/gra001.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Regions",
        route: "/regions",
        svgIcon: "media/icons/duotune/maps/map004.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default DocMenuConfig;
